footer {
  border: 1px solid #e7ebed;
  background-color: #f4f5f7;
  padding: 40px 0 40PX 0;
}

.footer-data ul {
  padding: 0;
  margin: 0;
}

.footer-data ul.flex-row li {
  display: inline;
}

.footer-data ul li a {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  color: #969696;
}

.footer-data h4 {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  color: #000;
  margin: 0 0 5px;
  text-transform: uppercase;
}

ul.flex-row {
  padding: 0;
}

.footer-data {
  margin: 20px 0 0 0;
}

.footer-data ul li {
  display: block;
}

.inner-footer {
  border: 1px solid #e7ebed;
  background-color: #f4f5f7;
  margin: 40px 0 0 0;
  padding: 25px 0 26px 0;
}

.innerfooter-data h4 {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  color: #000;
  margin: 0 0 5px;
  text-transform: uppercase;
}
.socials-link {
  margin: 0;
  padding: 0;
}
.socials-link li {
  display: inline-block;
  margin: 6px 10px 0 0px;
}
.btm-ftr-lst {
  margin: 24px 0 20px 0;
}

ul.list-f {
  padding: 0;
  margin: 0;
}

ul.list-f li {
  display: inline-block;
}
ul.list-f li a {
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  color: #969696;
  border-right: 1px solid #6a6a6a;
  margin-right: 10px;
  padding-right: 10px;
}
ul.list-f li:last-child a {
  border: 0;
}
/* @media (max-width: 768px) {
  img {
    width: 50px;
    height: 50px;
  }
} */
