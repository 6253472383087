.static-title h3 {
  line-height: 19px;
  color: #3575d3;
  text-transform: capitalize;
  text-align: center;
  background-color: #3d3f94;
  border-radius: 16px;
  padding: 15px;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}
.about-outer {
  background-color: #f2f1f1 !important;
}
.about-outer .lists-box .container {
  background-color: #f2f1f1 !important;
}
.about-inner {
  margin: 0px;
  /* padding: 30px 0px; */
  height: auto;
  width: 100%;
  background-color: #f2f1f1;
}
.about-inner p {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.9;
  margin-bottom: 15px;
}
.about-box {
  border: 1px dashed #ccc;
  height: auto;
  width: 100%;
  padding: 28px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0px #ccc;
}

.charmain-det h1 {
  font-size: 22px;
  color: #2b526f;
  margin: 0px 0 14px;
  padding: 0px;
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  font-weight: 700;
  letter-spacing: 2px;
}

.charmain-det p {
  font-size: 15px;
  color: #000;
  text-align: justify;
  line-height: 24px;
  font-style: italic;
}

.chairman-image img {
  height: auto;
  width: 100%;
  margin: 0px;
  padding: 0px;
  border: 1px solid #000;
}

.chairman-image h2 {
  font-size: 20px;
  color: #df0c0f;
  margin: 0px;
  padding: 6px 0 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}

.chairman-image h3 {
  font-size: 18px;
  color: #000;
  margin: 0px;
  text-align: center;
  padding: 0px;
  font-style: italic;
  text-transform: capitalize;
  font-weight: 600;
}
.vission-mission {
  margin: 10px 0 0;
}
.vission-mission img {
  height: 45px;
  margin: 0 7px 5px 0;
}

.vission-mission h3 {
  font-size: 22px;
  color: #fd0100;
  margin: 0px;
  padding: 0 0px;
  font-weight: 600;
  text-transform: capitalize;
  border-bottom: 1px dashed #27536c;
}

.vission-mission {
  margin: 20px 0 0;
  height: auto;
  width: 100%;
  padding: 20px;
  /* text-align: center; */
  background-color: #fff;
}

.vission-mission h4 {
  font-size: 22px;
  text-align: center;
  margin: 0px;
  padding: 5px 0 10px;
  text-transform: capitalize;
  color: #2b526f;
  text-decoration: underline;
  letter-spacing: 2px;
}

.vission-mission img {
  height: 80px;
  width: 80px;
  background-color: #2b526f1c;
  border-radius: 50%;
  padding: 11px;
  border: 1px solid #000;
  text-align: center;
}

.button-link {
  background: none;
  border: none;
  color: blue;
}

.about-us {
  border: 2px solid #3d3f94;
  border-radius: 20px;
}

.tg-aboutus {
  /* margin: 10px; */
  /* overflow-y: scroll;
  max-height: 250px; */
  /* min-height: 350px; */
  height: 100%;
  margin-bottom: 10px;
}

.tg-aboutus {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.tg-aboutus::-webkit-scrollbar {
  display: none !important;
}
.tg-aboutus ::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.tg-aboutus p {
  letter-spacing: normal;
  line-height: 20px;
  margin: 0 0 20px;
}
.tg-leadership figure {
  top: 0;
  float: left;
  margin: 0 20px 0 0;
  width: 100%;
  position: relative;
}
.tg-leadership figure img {
  /* width: 100%; */
}
/* .tg-leadership figcaption {
  background-color: #3d3f94;
  border-radius: 17px;
  padding: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
} */
@media (max-width: 991px) {
  .tg-leadership figure {
    width: 100%;
    /* float: left; */
    position: relative;
  }
  .static-title h3 {
    /* margin: 0 0 15px; */
    line-height: 19px;
    color: #3575d3;
    /* margin-top: 10px; */
    /* text-align: center; */
    /* text-transform: capitalize; */
  }
  .static-title {
    /* padding: 20px; */
  }
}
@media (max-width: 786px) {
  .tg-leadership figure {
    /* width: 100%; */
    /* float: left; */
    position: relative;
    text-align: center;
    max-width: 200px;
    /* width: 60%; */
  }
}
/* .tg-leadership figure:before {
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border: 7px solid rgba(255, 255, 255, 0.5);
} */
