.minimenu {
  border-top: 0;
  padding: 0px 0px 0px;
  margin: 0px 0px 0px;
  background: #f0f0ff;
  border-bottom: 1px solid #f3f3f3;
}
.minimenu .navbar {
  padding: 0px;
  justify-content: center;
  text-align: right;
}
.minimenu .navbar ul {
  padding: 0px;
  margin: 0px;
  width: 100%;
  text-align: left;
}
.minimenu .navbar ul li {
  display: inline-block;
  padding: 5px 0;
}
.minimenu .navbar ul li:first-child a {
  padding-left: 0px;
}
.minimenu .navbar ul li:last-child a {
  padding-right: 0px;
}
.hidecourse {
  display: none;
}
.minimenu .navbar ul li a {
  color: rgb(0 0 0);
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
  padding: 0px 25px;
  display: block;
  border-right: 1px dashed #accac4;
}

.minimenu .navbar ul li:last-child a {
  border: 0;
}
.minimenu2 .navbar ul li:last-child a {
  border: 0;
}

.minimenu2 {
  border-top: 0;
  padding: 0px 0px 0px;
  margin: 0px 0px 0px;
  background: #3d3f94;
  border-bottom: 2px solid #f3f3f3;
  /* position: absolute; */
}
.minimenu2 .navbar {
  padding: 0px;
  justify-content: center;
  text-align: right;
}
.minimenu2 .navbar ul {
  padding: 0px;
  margin: 0px;
  width: 100%;
  text-align: left;
}
.minimenu2 .navbar ul li {
  display: inline-block;
  padding: 5px 0;
  position: relative;
}
.minimenu2 .navbar ul li:first-child a {
  padding-left: 0px;
}
.minimenu2 .navbar ul li:last-child a {
  padding-right: 0px;
}
.Dropdowns {
  position: absolute;
  top: 26px;
  display: none;
  background: #3d3f94;
  z-index: 2;
  width: 138px;
  padding: 11px 0 0;
  left:-18px
}
.SubDropdowns {
  position: absolute;
  top: 0px;
  display: none;
  background: #3d3f94;
  z-index: 2;
  width: 167px;
  padding: 11px 0 0;
  left: 90px;
  max-height: 200px;
  overflow-y: scroll;
}

.image-gallery-icon {
  z-index: 1;
}

.dropdownhover:hover .Dropdowns {
  display: block;
}

.subdropdownhover:hover .SubDropdowns {
  display: block;
}
.minimenu2 .navbar ul li .Dropdowns li {
  display: block;
}
.minimenu2 .navbar ul li .SubDropdowns li {
  display: block;
}

.minimenu2 .navbar ul .Dropdowns a {
  font-size: 14px;
  border-bottom: 1px dashed #caa4a4;
  border-radius: 0;
  padding: 2px 5px 7px 15px !important;
}

.minimenu2 .navbar ul .SubDropdowns a {
  font-size: 14px;
  border-bottom: 1px dashed #caa4a4;
  border-radius: 0;
  padding: 2px 5px 10px 15px !important;
}
.minimenu2 .navbar ul li a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  letter-spacing: 0.4px;
  text-decoration: none;
  padding: 5px 21px;
  display: block;
  text-transform: uppercase;
  border-right: 1px dashed #caa4a4;
}
.barsicon {
  display: none;
}
@media (max-width: 600px) {
  /* .btn-close {
    display: none;
  } */
  .minimenu2 {
    border-top: 0;
    padding: 8px 14px 8px;
    margin: 0px 0px 0px;
    background: #3d3f94;
    border-bottom: 0;
  }
  #search-d .col-md-9.d-flex {
    display: block !important;
  }
  .logo {
    padding: 0;
    max-width: 100px !important;
    color: #3d3f94;
    margin: 0 auto;
  }
  .usersignup {
    text-align: center;
    padding: 14px 0 0;
    letter-spacing: 0.3px;
  }
  .usersignup h5 {
    font-size: 16px !important;
  }
  .usersignup h4 {
    font-weight: bold;
    color: #3d3f94;
    font-size: 14px !important;
  }
  .usersignup h6 {
    font-size: 10px !important;
  }
  .css-fvnb3i {
    display: none;
  }
  .minimenu2 .navbar {
    padding: 0 19px;
    justify-content: center;
    text-align: right;
    position: fixed;
    right: 0;
    display: none;
    height: 100%;
    top: 0;
    background: #3d3f94;
    z-index: 3;
    width: 50%;
  }
  button.barsicon {
    background: #fff;
    font-size: 23px;
    border: 0;
    display: block;
    margin: 0 0 0 auto;
  }
  .minimenu .navbar ul li a {
    color: rgb(0 0 0);
    font-size: 9px;
    font-weight: 400;
    text-decoration: none;
    padding: 0px 4px;
    display: block;
    border-right: 1px dashed #accac4;
  }
  .NOtification {
    margin: 0 0 20px 0;
    border: 1px solid #dbdce6;
    border-radius: 4px;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 5%);
  }

  .aNavtiveData {
    width: calc(100% - 0px);
    padding: 24px 0 1px 3px;
  }
  .icon-boxMain .col-md-4.col-sm-4 {
    padding: 0 5px;
    width: 50%;
  }
  .footer-logo {
    max-width: 150px;
    margin: 0 auto 20px;
    border-radius: 10px;
    overflow: hidden;
  }
  .footer-data {
    margin: 0;
  }
  .notifgsec ul li a {
    color: #fff;
    font-size: 15px;
    border-right: 0;
    display: block;
    padding: 15px 8px 8px 6px;
  }
  .sidefilters {
    padding: 10px 20px 0 0;
    display: none;
    margin: -34px 0 0;
  }
  .Litingsidesbar .col-md-9 {
    -webkit-box-flex: 0;
    padding: 0 24px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .Litingsidesbar .col-md-9 {
    -webkit-box-flex: 0;
    padding: 0 24px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .full-proper-list {
    width: 100%;
    padding: 10px 0px 0 0;
    /* display: block; */
    margin: 0;
  }
  .store_image img {
    border-radius: 4px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .ppl-0 {
    padding: 0px 21px;
    order: 2;
  }
  .minimenu2 .navbar ul li {
    display: block;
    padding: 5px 0;
    position: relative;
  }

  .minimenu2 .navbar ul li a {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    letter-spacing: 0.4px;
    text-decoration: none;
    padding: 12px 5px;
    display: block;
    text-transform: uppercase;
    border: 0;
    border-bottom: 1px dashed #caa4a4;
  }
  .minimenu2 .navbar {
    padding: 0 19px;
    justify-content: center;
    text-align: right;
    position: fixed;
    right: 0;
    display: none;
    height: 100%;
    top: 0;
    background: #3d3f94;
    z-index: 3;
    width: 63%;
  }
  button.barsicon {
    background: #fff;
    font-size: 23px;
    border: 0;
    display: block;
    margin: 0 0 0 auto;
  }
}
.logo {
  padding: -2px 0 0;
  max-width: 125px;
  color: #3d3f94;
}
.search-d {
  padding: 6px 15px;
}

.usersignup h4 {
  font-weight: bold;
  color: #3d3f94;
  font-size: 20px;
  margin: 0 0 4px 0;
  text-transform: capitalize;
}

.usersignup h5 {
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 2px;
  color: #ff4700;
}

.usersignup h3 {
  font-size: 16px;
  font-weight: 400;
  color: #f67f24;
}

.usersignup h6 {
  font-size: 18px;
  color: #2b2727;
  font-weight: 500;
  margin: 0 0 3px;
}
@media (max-width: 332px) {
  .logo {
    max-width: 80px !important;
  }
  .usersignup h5 {
    font-size: 13px !important;
  }
  .usersignup h4 {
    font-size: 10px !important;
  }
  .usersignup h6 {
    font-size: 8px !important;
  }
  .minimenu .navbar ul li a {
    font-size: 8px;
  }
}
@media (min-width: 768px) {
  .logo {
    max-width: 110px !important;
  }
}
@media (max-width: 1180px) {
  .usersignup h5 {
    font-size: 20px;
  }
  .usersignup h4 {
    font-size: 16px;
  }
  .usersignup h6 {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .usersignup h5 {
    font-size: 20px;
  }
  .usersignup h4 {
    font-size: 16px;
  }
  .usersignup h6 {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  .logo {
    max-width: 90px !important;
  }
  .usersignup h5 {
    font-size: 16px !important;
    text-align: center;
  }
  .usersignup h4 {
    font-size: 14px !important;
    text-align: center;
  }
  .usersignup h6 {
    font-size: 12px !important;
    text-align: center;
  }
  .search-box.css-fvnb3i {
    display: none;
  }
  .minimenu .navbar ul li a {
    font-size: 11px;
    padding: 0px 5px;
  }
  .minimenu2 .navbar ul li a {
    font-size: 12px;
    padding: 3px 7px;
  }
}
.btn-close {
  margin: 0;
  border: 0;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;
  color: black;
  display: none;

  right: 35px;
  position: relative;
  opacity: 1;
  background-color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.btn-close .icon-cross {
  margin-top: 10px;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 30px;
  height: 30px;
}
.btn-close .icon-cross:before,
.btn-close .icon-cross:after {
  content: "";
  position: absolute;
  top: 17px;
  left: 0;
  right: 0;
  height: 6px;

  border-radius: 6px;
}
.btn-close .icon-cross:before {
  transform: rotate(45deg);
}
.btn-close .icon-cross:after {
  transform: rotate(-45deg);
}
.btn-close .icon-cross span {
  display: block;
}
.btn-close:hover,
.btn-close:focus {
  transform: rotateZ(90deg);
}
