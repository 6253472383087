.listSS {
  padding: 10px 15px 20px;
  justify-content: center;
  text-align: center;
}

section.topper-student {
  padding: 40px 0;
}
.topper-student .container {
  border: 2px solid #1169af;
}

.topper-student .page_Tile {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  margin: -1px -16px 0 -15px;
  background: #1169af;
}
.page_Tile span {
  display: inline-block;
  padding: 0px 0 8px;
  text-transform: uppercase;
  font-size: 19px;
  /* opacity: 0.8; */
}
.serach-form {
  padding: 20px 0 10px;
}

.listSS label {
  margin: 0 20px 0 0;
  font-size: 13px;
  font-weight: 500;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgb(185 186 187 / 20%);
  vertical-align: revert;
  color: #444444;
  visibility: visible;
  letter-spacing: 0.5px;
  padding: 8px 16px;
  text-transform: uppercase;
  border-radius: 3px;
  width: 31%;
}
.listSS label span {
  display: inline-block;
  vertical-align: text-bottom;
  margin: 0 0px 0 4px;
}
label.active {
  background: #1169af;
  color: #fff;
}
.selectbox {
  display: flex;
  flex-wrap: wrap;
  padding: 0 17px;
}
.boxes {
  width: 50%;
  padding: 0 40px 15px 0;
  max-width: 339px;
}
.boxes select.form-control {
  box-shadow: none;
  border-radius: 4px;
  border: 0;
  box-shadow: 0 1px 4px 0 rgb(185 186 187 / 20%);
  padding: 11px 8px;
  height: 42px;
  font-weight: 500;
}
.boxes .form-control {
  box-shadow: none;
  border-radius: 4px;
  border: 0;
  box-shadow: 0 1px 4px 0 rgb(185 186 187 / 20%);
  padding: 11px 8px;
  height: 42px;
  font-weight: 500;
}
.download_pdf {
  text-align: right;
  padding: 0px 0 15px;
  font-weight: 600;
  text-transform: capitalize;
  /* border: 1px solid red; */
}
.download_pdf a {
  font-size: 13px;
  border: 1px solid #d4d4d4;
  padding: 8px 15px;
  border-radius: 8px;
  background: #fff;
  margin: 0 12px 0 0;
  color: #252424;
}
button.btn.btn-reset {
  background: #2cb502;
  color: #fff;
  padding: 12px 60px;
  box-shadow: none;
  border-radius: 43px;
  text-transform: uppercase;
  font-weight: 600;
}
/* .boxes select.form-control {
  box-shadow: none;
  border-radius: 4px;
  border: 0;
  box-shadow: 0 1px 4px 0 rgb(185 186 187 / 20%);
  padding: 11px 8px;
  height: 42px;
  font-weight: 500;
} */
/* .boxes .form-control {
  box-shadow: none;
  border-radius: 4px;
  border: 0;
  box-shadow: 0 1px 4px 0 rgb(185 186 187 / 20%);
  padding: 11px 8px;
  height: 42px;
  font-weight: 500;
} */
.btn_yp {
  padding: 25px 0 0 70px !important;
}
.filter-button {
  background: #2cb502;
  color: #fff;
  padding: 12px 60px;
  box-shadow: none;
  border-radius: 43px;
  text-transform: uppercase;
  font-weight: 600;
}
