.student-layout .menu{

}
.student-layout .menu .barsicon{
    display: none;
}

@media (max-width: 600px) {
    .student-layout .menu button.barsicon {
        background: #fff;
        font-size: 23px;
        border: 0;
        display: block;
        margin: 0
      }
}