.sorry {
  margin: 3rem;
  text-align: center;
  min-height: 70vh;
  justify-content: center;
}

.sorry > div {
  position: relative;
}

.sorry h1 {
  font-size: 100px;
  font-weight: 700;
  line-height: 1;
  color: #0066b2;
  font-family: "Akzidenz Grotesk", sans-serif;
  margin: 0;
}

.sorry h2 {
  font-family: "AkzidenzGroteskBE-CnIt", sans-serif;
  font-weight: 300;
  color: #0066b2;
  font-size: 42px;
  line-height: 1;
  margin: 0;
}

.sorry p {
  max-width: 220px;
  margin: 15px auto 30px auto;
  font-family: "Akzidenz Grotesk", sans-serif;
  color: #2e2c2d;
  line-height: 1.5;
  font-size: 16px;
}

.sorry svg {
  display: block;
  margin: 0 auto 30px auto;
  width: 100%;
  height: auto;
  max-width: 185px;
}

.sorry svg path {
  fill: #0066b2;
}
.sorry svg circle {
  fill: #0066b2;
}

.sorry a {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #2e2c2d;
  transition: border 0.3s, color 0.3s, background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0);
  display: inline-block;
  max-width: 100px;
  width: 100%;
  margin: 0 auto;
}
.sorry a:hover {
  background-color: #2e2c2d;
  box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0.65);
  border: 1px solid #ffffff;
  color: #ffffff;
}

.sorry a:focus {
  box-shadow: 0 0 8px 0 rgba(46, 44, 45, 0);
  border: 1px solid #2e2c2d;
  background-color: #ffffff;
  color: #2e2c2d;
}

.sorry a:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
  outline-color: #2e2c2d;
}

.sorry a:disabled {
  color: rgba(#ffffff, 0.33);
}
