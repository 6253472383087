.title-box h4 {
  text-align: center;
  font-size: 34px;
  line-height: 26px;
  margin-top: 10px;
  margin-bottom: 16px;
}

.title-box h6 {
  text-align: center;
  font-size: 14px;
}

.title-box {
  color: #958d8d;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0 10px 0;
  margin: 0 0 14px 0;
}
