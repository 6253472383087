@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

img {
  max-width: 100%;
}

section.logindesign {
  padding: 60px 0;
}

.formBox2 {
  margin: 0 0 20px;
}

.telBoxform {
  margin: 0 0 20px;
}
.Formboxlogin {
  min-height: 500px;
  max-width: 400px;
  background: #fff;
  margin: 0 auto;
}

.Login-header {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 24px;
  text-align: center;
}

span.Loginor {
  font-size: 16px;
  font-weight: 400;
  color: #535766;
}

.innerForm {
  padding: 24px 25px;
}

.telBox {
  position: relative;
  display: flex;
  border: 1px solid #d4d5d9;
  font-size: 14px;
  padding: 4px 12px;
  line-height: 12px;
  height: auto;
  color: #282c3f;
  margin-bottom: 24px;
}

.telBox .form-control {
  border: 0;
  padding: 3px 14px;
}

.formBox2 .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px;
}
.formBox2 .form-control::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
}
.formBox2 .form-control:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px;
}
.formBox2 .form-control:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
}
.telBox span {
  line-height: 27px;
  margin: 0 0 0 0px;
  border-right: 2px solid #e6e6e6;
  padding: 0 10px 0 0px;
}
.kCeVIa {
  font-family: "Fira Sans", sans-serif;
  background-image: url(https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.238/static/media/default_left.e74de3ec.svg),
    url(https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.238/static/media/default_right.6ece9751.svg);
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-size: 500px, 500px;
  background-position: left bottom, right bottom;
}

.jxPJzF {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgb(250, 251, 252);
}

.Termslink {
  margin-bottom: 24px;
  font-weight: 400;
  color: #b8b7b7;
  line-height: 24px;
}

.Termslink a {
  color: #ff4c3b;
  padding: 8px 0;
}

.btn-continues {
  background: #3b6eff;
  width: 100%;
  color: #fff;
  padding: 9px 0;
}
.btn-continues:hover {
  color: #fff;
}
.My-help {
  margin: 14px 0 0;
}

.My-help span {
  font-weight: 600;
  color: #ff4c3b;
}
