.galleryBox {
  border: 2px solid #3d3f94;
  border-radius: 20px;
}
.galleryBox img {
  object-fit: cover;
  object-position: center;
  height: 200px;
  width: 270px;
}
.galleryBox figcaption {
  background-color: #3d3f94;
  border-radius: 20px;
  padding: 5px;
}
.galleryBox h6 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.galleryBox h6:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
  overflow: visible;
  position: relative;
  background-color: white;

  box-shadow: 0 0 4px 0 black;
  border-radius: 1px;
}
