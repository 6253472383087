.font-weight-bold {
  font-weight: 700 !important;
}
section p {
  color: #707070;
  line-height: 1.9;
  margin: 0 0 1.2em;
}
html .text-color-tertiary:not(.no-skin),
html .text-tertiary:not(.no-skin) {
  color: #ed153e !important;
}
h2 {
  font-size: 28.8px;
  font-size: 1.8rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1c1f21;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 6.4px 0;
  margin: 0 0 0.4rem 0;
  letter-spacing: -0.5px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

.font-primary {
  font-family: "Montserrat", sans-serif !important;
}

.text-color-dark {
  color: #1c1f21 !important;
}
.page-header {
  /* background-color: #f1f3f7; */
  margin: 0 0 10px 0;
  min-height: 50px;
  padding: 10px 0;
  position: relative;
  text-align: center;
}

.object-fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.img-thumbnail-small {
  max-width: 25px;
}

.img-thumbnail-medium {
  max-width: 80px;
}

.img-thumbnail-big {
  max-width: 125px;
}

.font-weight-bold {
  font-weight: 700 !important;
}
.line-height-2 {
  line-height: 1.2 !important;
}
.text-3 {
  font-size: 16px !important;
  font-size: 1rem !important;
}
.link-color-dark {
  color: #1c1f21;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-0 {
  font-size: 11.2px !important;
  font-size: 0.7rem !important;
}

.courses {
  align-items: center;
  background-color: #f2f2f2;
  display: flex;
  font-family: "Merriweather", serif;
  flex-wrap: wrap;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.course-list .person {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 280px;
}
.course-list li {
  display: inline-block;
}
.course-list .container {
  /* border-radius: 50%; */
  height: 312px;
  -webkit-tap-highlight-color: transparent;
  transform: scale(0.48);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 400px;
}
.course-list .container:after {
  background-color: #f2f2f2;
  content: "";
  height: 10px;
  position: absolute;
  top: 390px;
  width: 100%;
}
.course-list .container:hover {
  transform: scale(0.54);
}
.course-list .container-inner {
  /* clip-path: path(
    "M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z"
  ); */
  position: relative;
  transform-origin: 50%;
  top: -200px;
}
.course-list li {
  list-style: none;
  display: inline;
}
.course-list .circle {
  background-color: #fee7d3;
  border-radius: 5%;
  cursor: pointer;
  height: 380px;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 210px;
  width: 380px;
}
.course-list .img {
  pointer-events: none;
  position: relative;
  transform: translateY(20px) scale(1.15);
  transform-origin: 50% bottom;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.course-list .container:hover .img {
  transform: translateY(0) scale(1.2);
}
.course-list .img1 {
  left: 22px;
  top: 164px;
  width: 340px;
}
.course-list .img2 {
  left: -46px;
  top: 174px;
  width: 444px;
}
.course-list .img3 {
  left: -16px;
  top: 144px;
  width: 466px;
}
.course-list .divider {
  background-color: #ca6060;
  height: 1px;
  width: 160px;
}
.course-list .name {
  color: #404245;
  font-size: 36px;
  font-weight: 600;
  margin-top: 16px;
  text-align: center;
}
.course-list .title {
  color: #6e6e6e;
  font-family: arial;
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
}
pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
