.icon-boxMain {
  background: rgb(208, 168, 255);
  background: rgb(208, 168, 255);
  background: linear-gradient(
    84deg,
    rgb(247 240 255 / 27%) 3%,
    rgb(202 243 252 / 26%) 95%
  );
  border: 1px solid #78f4b5;
  padding: 30px 20px 25px 20px;
  border-radius: 5px;
  margin: 30px 0;
  min-height: 301px;
  box-shadow: 0px 0px 2px 0 rgb(255 192 76 / 0%);
}
.icon-boxMain2 {
  background: linear-gradient(
    84deg,
    rgb(255 251 240 / 27%) 3%,
    rgb(252 244 202 / 26%) 95%
  );
  border: 1px solid #ffc150;
}
.icon-boxMain3 {
  background: linear-gradient(
    84deg,
    rgb(226 215 247 / 27%) 3%,
    rgb(255 129 198 / 6%) 95%
  );
  border: 1px solid rgb(208, 168, 255);
}
h4.icon-box-title {
  text-align: center;
  font-size: 20px;
  color: #000;
  margin: 0 0 16px 0;
}

h5.TxtBox {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}
.icon-box img {
  max-width: 40px;
  margin: 11px auto;
  opacity: 0.8;
}

.icon-box a {
  display: block;
  text-align: center;
}

.icon-box {
  background: rgb(255 255 255 / 42%);
  box-shadow: 0px 0px 1px 0 rgb(0 0 0 / 11%);
  border-radius: 10px;
  padding: 10px 0;
  margin: 0 0 15px;
}

.icon-boxMain .col-md-4.col-sm-4 {
  padding: 0 5px;
}
.upboxs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: rgb(255 255 255 / 51%);
  border-bottom: 1px solid #eee;
  border-radius: 0px;
  padding: 3px 8px 11px;
  margin: 0 0 5px;
}
a {
  color: #337ab7;
  text-decoration: none !important;
}
