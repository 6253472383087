.grid-data {
  border-top: 4px solid;
  display: flex;
  background: #fff;
  padding: 25px 15px 10px;
  border-radius: 10px;
  height: 130px;
}

/* .grid-box .col-md-2 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 10px;
} */

.img-data img {
  max-width: 44px;
}

.grid-content {
  padding: 0 0 0 15px;
}

.grid-content h5 {
  font-size: 16px;
  font-weight: 400;
  /* color: #343434; */
  margin: 1px 0 10px;
}

.grid-content h6 {
  color: #070606;
  font-size: 28px;
  font-weight: 300;
}

.grid-content h6 span {
  font-size: 15px;
  color: #000;
  margin: 0 0 0 4px;
}
.inner {
  min-height: 453px;
}

.inner {
  background-color: #fff !important;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 2%);
  padding: 15px;
}
.inner {
  min-height: 453px;
}

.inner {
  background-color: #fff !important;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 2%);
  padding: 15px;
}
.table-box {
  border: 1px solid #ccc;
  padding: 7px 17px 5px 17px;
  min-height: 51px;
  line-height: 31px;
}
.inner h3 {
  color: #000;
  font-size: 16px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 31px -15px 0px -15px;
  padding: 11px;
  background: rgb(61 63 148 / 14%);
}
.img-data {
  padding: 7px 0 0 0;
  width: 44px;
  text-align: center;
  height: 44px;
  border-radius: 100%;
}
