.breadcm ol.breadcrumb.purple.lighten-4 {
  background: transparent;
  padding: 10px 0 0 0;
}

.breadcm ol.breadcrumb a {
  color: #908e8e;
  font-size: 14px;
}

.breadcm ol.breadcrumb li::before {
  display: none !important;
}
.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.lists-box .container {
  max-width: 1200px;
  background: #ffffff;
  margin-top: 34px;
  padding: 23px 18px;
  margin-bottom: 30px;
  border-style: solid;
  border-color: blue;
}
