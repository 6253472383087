.certificate-table {
  padding: 23px 18px;
  width: 100%;
  background: #fff;
}
.certificate-table-data {
  width: 100%;
  padding: 16px 47px;
}
.certificate-table-td {
  border: 1px solid #5b5858;
  padding: 8px 11px;
}
.certificate-table-td-border {
  border: 1px solid #5b5858;
  padding: 8px 11px;
}

.centername {
  display: block;
  margin: 42px 0 -49px 0;
}
/* td {
  text-transform: capitalize;
} */

.centerNas {
  font-size: 22px;
  font-weight: 700;
}
.form-control {
  font-size: 13px !important;
}
.h5 {
  text-align: center;
  font-size: 27px;
  width: 100%;
  margin: 16px 6px 21px;
}
.table thead th {
  font-size: 12px;
}
.para {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 23px;
  width: 100%;
}

.singmd {
  width: 50%;
}

.singmde {
  width: 50%;
  text-align: right;
}
.centerCod {
  font-size: 20px;
  color: #39b9ea;
}

.certificateTable {
  width: 100%;
  border-collapse: collapse;
  border-width: 1px;
}
.certificateTable tr td {
  border-width: 1px;
}
