.assessment {
  height: 100vh;
  /* width: 1171px; */
  background-color: aliceblue;
  /* margin-left: -208px; */
  text-align: left;
  padding-left: 100px;
  padding-right: 100px;
}
.assessment .time-left {
  float: right;
  font-weight: bold;
  margin-right: 25px;
}
.assessment .question {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
}

.exam .box-welcome {
  height: 100vh;
  position: relative;
  display: flex;
  width: 100%;
  /* background: url("../../../../../public/images/logo.png") no-repeat center; */
  /* opacity: 0.1; */
}
.exam .box-welcome::before {
  content: "";
  background: url("../../../../../public/images/logo.png") no-repeat center
    fixed;
  /* background-size: cover; */
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
}
.exam .box-welcome img {
  margin: auto;
  display: block;
}

legend {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: threedface;
  border-image: initial;
}

button:disabled {
  cursor: not-allowed;
}
.watermarked {
  position: relative;
  width: 100%;
}

.watermarked:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("https://npvec.com/images/logo.png");
  background-size: 100px 100px;
  background-position: 30px 30px;
  background-repeat: no-repeat;
  opacity: 0.7;
}

.back-image {
  opacity: 0.1;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
}
.exam-list table thead th {
  background-color: whitesmoke;
  color: black;
}
