.icon-data {
  color: #fff;
  font-weight: 800;
  font-size: 42px;
  margin: 18px 0 0;
  text-shadow: 1px 1px #4684ef;
}
.col-site-data {
  text-align: center;
  margin: 0 10px;
  border-radius: 12px;
  padding: 23px 12px;
  height: 100%;
}
.col-site-data.pinkbg {
  background: rgb(255, 156, 170);
  background: linear-gradient(
    to right,
    rgba(255, 156, 170, 1) 0%,
    rgba(255, 159, 170, 1) 42%,
    rgba(255, 140, 165, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9caa', endColorstr='#ff8ca5',GradientType=1 );
}
.col-site-data.perbg {
  background: rgb(198, 136, 252);
  background: linear-gradient(
    to right,
    rgba(198, 136, 252, 1) 0%,
    rgba(190, 122, 250, 1) 42%,
    rgba(167, 83, 242, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c688fc', endColorstr='#a753f2',GradientType=1 );
}
.col-site-data.blugbg {
  background: rgb(145, 193, 255);
  background: linear-gradient(
    to right,
    rgba(145, 193, 255, 1) 0%,
    rgba(145, 193, 255, 1) 42%,
    rgba(123, 163, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#91c1ff', endColorstr='#7ba3ff',GradientType=1 );
}
.col-site-data h4 {
  color: #fff;
  font-weight: 800;
  font-size: 20px;
  margin: 10px 0 0;
  text-shadow: 1px 1px #4684ef;
}

.row-data {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 50px 0px;
}
@media (max-width: 332px) {
}
@media (max-width: 600px) {
  .col-site-data h4 {
    font-size: 15px;
    margin: 0;
  }
  .icon-data {
    font-size: 17px;
    margin: 0 0 0;
  }
}
