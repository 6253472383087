.SlideBoxs {
  padding: 25px 0;
}
.upboxs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: rgb(255 255 255 / 51%);
  border-bottom: 1px solid #eee;
  border-radius: 0px;
  padding: 3px 8px 11px;
  margin: 0 0 5px;
}
.upData {
  width: calc(100% - 63px);
  padding: 4px 0 0 14px;
}

.NOtification {
  border: 1px solid #dbdce6;
  border-radius: 4px;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 5%);
  /* max-height: 400px;
  min-height: 300px; */
}
.notification-items {
  overflow-y: scroll;
  max-height: 350px;
  /* min-height: 350px; */
  height: 100%;
  /* word-wrap: break-word; */
}
.notification-items h4 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.notification-items .heading {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.NOtification {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.NOtification::-webkit-scrollbar {
  display: none !important;
}
.NOtification ::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.upData h4 {
  font-size: 13px;
  color: #000;
  font-weight: 400;
  line-height: 16px;
}
.upData .heading {
  font-size: 13px !important;
  color: #000 !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}
.upDateimg img {
  border-radius: 9px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.upDate {
  background: #f3f4ff;
  width: 62px;
  border-radius: 15px;
  height: 62px;
  color: #3d3f94;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 0;
  text-align: center;
}
.upboxs:last-child {
  border: 0;
}
.upDate h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.upDateimg {
  background: #f3f4ff;
  width: 62px;
  border-radius: 15px;
  height: 62px;
  color: #9e0206;
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
  padding: 0px 0;
  text-align: center;
}
.upDate p {
  margin: 0;
}
.notifgsec {
  border-radius: 37px;
  background: #0062cc;
  padding: 8px 12px;
}

.notifg {
  color: #0062cc;
  padding: 5px 10px;
  background: #fff;
  font-size: 13px;
  font-weight: 800;
  text-align: center;
  border-radius: 35px;
}

.notifgsec ul {
  margin: 0;
  padding: 0;
}

.notifgsec ul li {
  display: inline-block;
}
.notifgsec ul li:last-child a {
  border: 0;
}
.notifgsec ul li a {
  color: #fff;
  font-size: 15px;
  border-right: 1px solid #ddd;
  display: block;
}
.homebannerL {
  padding: 0;
}
.homebannerL .owl-stage {
  margin-left: -150px;
}
.homebannerL .homeslider img {
  border-radius: 5px;
}
.homebannerL .owl-prev i,
.owl-gallery .owl-prev i {
  font-weight: 500;
}
.homebannerL .owl-prev,
.homebannerL .owl-next,
.owl-gallery .owl-prev,
.owl-gallery .owl-next {
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  border-radius: 45px !important;
  top: 46%;
  line-height: 19px !important;
  opacity: 0.9;
  padding: 4px 0 !important;
  color: #6e7578 !important;
  text-align: center;
  box-shadow: 0 0px 7px 0 rgb(70 70 70 / 30%);
  border: 1px solid #e3e3e3 !important;
  background: #fff !important;
  font-weight: 400 !important;
  font-size: 25px !important;
}
.homebannerL .owl-prev,
.owl-gallery .owl-prev {
  left: -1px;
}
.homebannerL .owl-next,
.owl-gallery .owl-next {
  right: -52px;
}
.homebannerL .owl-dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.NOtification h5 {
  background: #3d3f94;
  color: #fff;
  padding: 12px 8px;
  font-size: 16px;
  border-radius: 4px;
}
.image-gallery-slide {
  width: 100% !important;
  height: 400px !important;
}
.image-gallery-image {
  max-height: 100%;
  object-fit: cover !important;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 40px;
  width: 20px;
}
.image-gallery-slide img {
  width: 100% !important;
  height: 400px !important;
}
@media (max-width: 332px) {
  .notifgsec ul li a {
    font-size: 10px;
  }
}
@media (max-width: 600px) {
  .notifgsec ul li a {
    text-align: center;
  }
}
