section.Main_sect {
  padding: 50px 0 50px;
  background: #fbffff;
  margin-bottom: 20px;
}
section.Main_sect {
  padding: 50px 0 30px;
}
.Team-lefatbar {
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 1px rgb(0 0 0 / 5%);
  background: #f8f8ff;
  padding: 0 0 10px 0;
  border: 0px solid #cc0000;
  height: 100%;
}
section.about_Team {
  background: #313131;

  padding: 60px 0;
}
.Team-lefatbar ul {
  margin: 0;
  padding: 0;
}
.prodile-bio p {
  font-size: 13px;
  line-height: 22px;
}

.Team-lefatbar ul li {
  display: block;
}

.Team-lefatbar ul li a {
  color: #000000;
  font-size: 15px;
  margin: 0 0 5px 0;
  font-weight: 600;
  padding: 0 17px 7px;
  display: block;
  border-bottom: 2px solid rgba(8, 1, 1, 0.034);
  text-decoration: none;
  word-break: break-word;
}

.Team-lefatbar ul l:last-child a {
  margin: 0;
}
.Team-lefatbar ul li a:hover {
  color: #0d0e0e;
  font-weight: 700;
}
.Team-lefatbar h3 {
  color: #fff;
  font-size: 20px;
  background: #3d3f94;
  padding: 8px 15px;
  margin: 0 0 10px 0;
}
.aNavtive {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #fff;
  padding: 11px 11px;
  border-radius: 10px;
}

.aNavtiveimg {
  width: 389px;
  overflow: hidden;
  border-radius: 20px;
}

.aNavtiveData {
  width: calc(100% - 399px);
  padding: 0 0 1px 17px;
}

.aNavtiveData h5 {
  color: #242525;
  font-size: 33px;
}
.aNavtiveimg img {
  width: 100%;
}
.Sponnative {
  font-weight: 600;
  color: #d5d5d5;
  margin: 0 0 6px 0;
}

.Sponnative span {
  margin: 0 0 0 20px;
}
.Team_list .col-md-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
