.navbar-dashboard {
  background: #fff;
  height: 63px;
  padding: 0px 0;
  width: 100%;
  background: #fff;
  z-index: 100;
  background: -moz-linear-gradient(
    left,
    rgba(210, 228, 217, 1) 0%,
    rgba(210, 225, 218, 1) 41%,
    rgba(159, 162, 238, 1) 100%
  );
  /* background: -webkit-linear-gradient(left, rgba(210,228,217,1) 0%,rgba(210,225,218,1) 41%,rgba(159,162,238,1) 100%); */
  /* background: linear-gradient(to right, rgba(210,228,217,1) 0%,rgba(210,225,218,1) 41%,rgba(159,162,238,1) 100%); */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d2e4d9', endColorstr='#9fa2ee',GradientType=1 );
  display: block;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 3px rgb(0 0 0 / 10%);
  /* border-bottom: 1px solid #dddddd; */
}

img {
  max-width: 100%;
}
.main-wrapper {
  background: #f7f8ff;
  height: 100%;
  position: relative;
  width: 100%;
}

body {
  background: #f9fbfd !important;
}
.feechekd {
  background: #fff;
  padding: 20px;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 3px rgb(0 0 0 / 10%);
  text-align: center;
  border-radius: 19px;
}
.feechekd.pais {
  background: #beffd2;
}

.Applicationdata td table th {
  background: #fdfff0;
  font-weight: 700;
  color: #222;

  border: 1px solid #d9d9d9;
}
.Applicationdata td table td {
  background: #f0f4ff;
  border: 1px solid #eaeaea;
}
.Applicationdata td table {
  border: 0;
}
.paychk {
  color: #129133;
  font-weight: 800;
  margin: 18px 0 0;
}

.feechekd.pais i.fa.fa-check {
  font-size: 18px;
  color: #129133;
  background: #fff;
  border-radius: 100%;
  width: 30px;
  padding: 5px 0;
  margin: 0 7px 0 0;
  height: 30px;
}
.feechekd.pais i {
  color: #129133;
}
.feechekd a {
  background: #2c7be5;
  padding: 4px 15px;
  color: #fff;
  display: block;
  display: inline-block;
  border-radius: 31px;
}

.feechekd h5 {
  margin: 14px 0 15px 0;
  font-size: 15px;
}

.feechekd i {
  font-size: 28px;
  color: #2c7be5;
}
.college-name h1 {
  text-transform: uppercase;
  font-weight: 800;
  opacity: 0.9;
  font-size: 17px;
  word-spacing: 5px;
}

.navbar-dashboard .row {
  width: 100%;
}

.college-name {
  padding: 18px 0 0 8px;
  display: inline-block;
  height: 63px;
}

.search-dashboard {
  position: relative;
  width: 50%;
  border-radius: 20px;
  height: 43px;
  margin: 4px 0 0 0;
  background: #f8f8f8;
}

.search-dashboard .form-control {
  border: initial;
  background: transparent;
  outline: 0 !important;
  outline: initial !important;
  padding: 0.5rem 1rem;
  line-height: 1.75;
  font-size: 0.8rem;
  width: 93%;
  color: #3a3a3a;
}
.box-welcome {
  background: linear-gradient(91.29deg, #ebfcff 42.19%, #e0e7ff 100%) fixed;
  border-radius: 10px;
  padding: 8px 26px;
  margin-bottom: 30px;
}

.box-status {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.box-statusImg {
  max-width: 100px;
}

.box-statusData {
  padding: 25px 0 0 20px;
  width: calc(100% - 120px);
}

.box-statusData h2 {
  font-size: 22px;
}

.box-statusData h2 span {
  background: -webkit-linear-gradient(#978bf1, #138dfd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  word-wrap: break-word;
}

.bars-clr {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 10px 0;
  color: #1d1d1d;
  max-width: 289px;
}

.bars-clr .progress {
  width: calc(100% - 148px);
  height: 9px;
  margin: 6px 0 0 14px;
  /* border: 1px solid #c7d4f9; */
}

span.gendcent {
  color: #2c7be5;
  margin: 0px 0 0 12px;
}

ul.selectDrops {
  text-align: right;
  padding: 30px 0 0;
}

.selectDrops li {
  display: inline-block;
  margin: 0 0 0 8px;
  vertical-align: middle;
}

.dropdwonData {
  border: 1px solid #2c7be5;
  display: block;
  background: #ffffff80;
  padding: 5px 38px;
  border-radius: 10px;
  font-size: 13px;
  position: relative;
  font-weight: 400;
  text-align: left;
  text-decoration: none !important;
}
.dropdwonData:hover {
  background: #fff;
}
.dropdwonData span {
  display: block;
}

.dropdwonData span.iconsDrop {
  position: absolute;
  left: 12px;
  top: 13px;
}

.addNewchild {
  background: linear-gradient(90.14deg, #978bf1 0.19%, #138dfd 102.43%),
    linear-gradient(0deg, #2c7be5, #2c7be5);
  color: #fff;
  padding: 10px 25px;
  border-radius: 40px;
}
.Nodata {
  background: #fff;
  text-align: center;
  margin: 30px 0 30px;
  border-radius: 10px;
  padding: 20px 0;
}

.Nodata h4 {
  color: #7488a1;
  font-size: 18px;
  margin: 10px 0;
}

.btn-apply {
  border: 2px solid #ea1529;
  color: #fff;
  /* font-weight: 700; */
  border-radius: 30px;
  /* width: 50%; */
  padding: 6px 40px;
  background: #ea1529;
  font-weight: bold;
}
.Processimg {
  margin: -13px 0 0 0;
}

.Allprocess ul li h4 {
  font-size: 15px;
  font-weight: bold;
  position: relative;
}

.Allprocess ul li h5 {
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
}

.Edits {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: rgba(139, 158, 181, 0.5);
  font-size: 15px;
}

.Allprocess ul li.active h4::before {
  position: absolute;
  content: "";
  left: -31px;
  top: 5px;
  z-index: 2;
  height: 8px;
  border-radius: 100%;
  width: 8px;
  /* border: 5px solid #EFEFEF; */
  background: #2c7be5;
}

.Allprocess ul::before {
  position: absolute;
  content: "";
  left: 30px;
  height: 80%;
  display: none;
  width: 2px;
  background: #efefef;
}

.Allprocess ul li::before {
  position: absolute;
  content: "";
  left: -24px;
  height: 100%;
  width: 3px;
  background: #efefef;
}
.Allprocess ul li:last-child::before {
  display: none;
}
.Allprocess ul li.active::after {
  border: 3px solid #2c7be5;
  background: #fff;
}
.Allprocess ul li::after {
  position: absolute;
  content: "";
  left: -31px;
  top: 0;
  height: 18px;
  border-radius: 100%;
  width: 18px;
  border: 5px solid #efefef;
  background: #fff;
}
.Nowapply {
  margin-top: 20px;
}
.addNewchild:hover {
  color: #2c7be5;
  background: #fff;
  text-decoration: none;
}
.dropdwonData span.DropS {
  color: #000;
}
.dropdwonData i {
  font-size: 20px;
  position: absolute;
  right: 9px;
  top: 13px;
}
span.search-icon {
  font-size: 17px;
  border-radius: 10px;
  color: #d7d7d7;
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -4px;
  right: 3px;
  text-align: center;
  cursor: pointer;
}
.locationdropdown {
  border: 1px solid #558c6a;
  background: transparent;
  height: 38px;
  color: #558c6a;
  border-radius: 28px;
  padding: 0 16px;
  margin: 12px 0 0 30px;
  font-weight: 400;
  cursor: pointer;
}
.locationdropdown:hover {
  opacity: 0.8;
}
.locationdropdown svg {
  margin: 5px 1px -3px;
}
.navbar-autho {
  width: 400px;
  text-align: center;
  margin: 0 auto 0 0;
}
.cartitembox {
  background: #fff;
  margin: 0 0 20px 0;
  position: relative;
  padding: 12px;
  border-radius: 9px;
  box-shadow: 0 0 3px rgb(0 0 0 / 8%);
}
.cartTop {
  padding-bottom: 18px;
  width: 100%;
}
.cartThumb {
  width: 214px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 214px;
  flex: 0 0 214px;
  max-width: 214px;
}
.cartImg {
  text-align: center;
  width: 214px;
  background: #eef2f6;
  height: 150px;
  overflow: hidden;
  border-radius: 13px;
  padding: 18px 0;
}
.cartCentent {
  width: calc(100% - 214px);
  padding: 8px 0 0 47px;
}
.cartitembox .quick-names {
  width: 100%;
  padding: 20px 0 0 10px;
}
.QVasName {
  font-weight: 700;
  font-size: 23px;
  line-height: 29px;
  text-transform: capitalize;
}
button.multiselect {
  width: 100%;
  text-align: left;
  border: 1px solid #eeeeee;
  background: #fff;
}
.multiselect-container > li > a > label {
  margin: 0;
  height: 100%;
  cursor: pointer;
  font-weight: 400;
  padding: 3px 20px 3px 15px !important;
  width: 100%;
}
.QLocationmark {
  color: #7488a1;
  /* height: 89px; */
  display: inline-block;
  /* width: 200px; */
  font-size: 18px;
  /* border-right: 1px solid #e1e1e1; */
  padding: 6px 1px 6px 18px;
  background-image: url("/public/images/icons.png");
  background-size: 340px;
  background-position: -95px -70px;
  /* background-size: cover; */
  font-weight: 500;
  background-repeat: no-repeat;
}
.cartAdate {
  width: calc(100% - 115px);
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  padding: 21px 0 0 0px;
}
.cartAdateP {
  width: 250px;
  padding-right: 25px;
}
.col-Dates {
  padding: 0 40px 0 0;
}
.col-Dates h5 {
  color: #8b9eb5;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 5px 0;
}
.col-Dates h6 {
  font-size: 15px;
  color: #223347;
}
.cartitemprice {
  text-align: right;
  font-size: 25px;
  font-weight: 700;
}
.txis {
  color: #7488a1;
  font-size: 15px;
  display: block;
  /* font-style: italic; */
  font-weight: 400;
}
.navbar-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-right li {
  display: inline-block;
  padding: 0 0 0 28px;
}
.navbar-right li a {
  display: block;
  color: #222222;
  text-transform: uppercase;
  font-size: 15px;
}
ul.navbar-nav.navbar-right {
  width: calc(100% - 400px);
  justify-content: flex-end;
}
.active-bar {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 0 0;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
}

.notification-bar {
  width: 100%;
  padding: 0 0 0 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
}

.user-account img {
  margin-left: 15px;
  border-radius: 30px;
  width: 48px;
  height: 48px;
}

.msq-box {
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 100%;
  color: #999999;
  background: #f7f8ff;
  font-size: 20px;
  margin: 5px 14px;
  padding: 4px 8px;
  position: relative;
}
.images {
  display: flex;
  flex-wrap: wrap;
}

.images .img span {
  background: #ff2300;
  color: #fff;
  padding: 1px 11px;
  border-radius: 16px;
  text-transform: capitalize;
  line-height: 25px;
  speak: inherit;
  display: inline !important;
  cursor: pointer;
}
.msq-box span {
  position: absolute;
  right: -5px;
  top: 0px;
  background: #fc8c8c;
  color: #fff;
  font-size: 12px;
  height: 21px;
  display: block;
  line-height: 18px;
  width: 21px;
  border-radius: 100%;
}

.msq-box a {
  color: #999999;
  display: block;
  line-height: 28px;
}

.bell-notification {
  height: 31px;
  width: 31px;
  text-align: center;
  border-radius: 100%;
  color: #999999;
  background: #f7f8ff;
  font-size: 15px;
  margin: 6px 20px 0 0;
  padding: 2px 8px;
  position: relative;
}

.bell-notification a {
  color: #999999;
  display: block;
  line-height: 28px;
}

.bell-notification span {
  position: absolute;
  right: -4px;
  top: -1px;
  background: #2c7be5;
  color: #fff;
  font-size: 11px;
  height: 16px;
  display: block;
  line-height: 15px;
  width: 16px;
  border-radius: 100%;
}
.logo-dashboard {
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
}
.ck-content {
  min-height: 200px;
  border-radius: 0.42rem !important;
}
.images .img:hover span {
  display: block;
  color: #fff;
}
.galleryopload {
  font-weight: 600;
  margin: 16px 0;
  color: #282828;
  padding: 0px 0 10px;
  font-size: 16px;
  font-family: open sans, sans-serif;
  border-bottom: 1px solid #efefef;
}
.sericeformd {
  display: flex;
}
.icon_ads {
  display: block;
  margin: 0 0 8px;
}
.images .pic {
  background-color: #ffffff;
  align-self: center;
  text-align: center;
  padding: 25px 0;
  text-transform: capitalize;
  color: #666666;
  font-size: 15px;
  position: relative;
  cursor: pointer;
  font-weight: 300;
  border: 1px dashed #dbdbdb;
  box-shadow: 0 0px 6px 3px rgba(114, 114, 114, 0.14);
}
#add_services {
  margin-left: 20px;
  border: 1px solid lightgrey;
  width: 100px;
  border-radius: 3px;
  color: #6e6e6e;
  height: 38px;
  font-size: 15px;
  font-weight: 500;
  background: transparent;
  margin-top: 1px;
}
label.serviceBox {
  padding: 0 20px 5px 0px;
  display: inline-block;
  width: auto;
  vertical-align: top;
}
.images .img,
.images .pic {
  /* flex-basis: 13%; */
  margin-bottom: 10px;
  border-radius: 4px;
  width: 145px;
  height: 122px;
  background-size: cover;
  border: 1px solid #dfd9d9;
  display: inline-block;
  margin: 0 17px 0 0px;
}
.filegaleery input[type="file"] {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  top: 0;
}
#myDivloader img#loading-image {
  width: 61px;
  margin: 15px 0 0;
}
.form-error {
  color: #d6010e !important;
  font-size: 14px;
}
#myDivloader {
  z-index: 1;
  height: 330px;
  width: 200px;
  width: 112px;
  height: 93px;
  /* display: none; */
  left: 0;
  text-align: center;
  top: 0;
  border: 1px solid #efefef;
  background: rgba(255, 255, 255, 0.78);
  margin: 0 20px 0 0;
  border-radius: 5px;
}
.filegaleery {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
}
.container4 {
  border-bottom: 1px solid #3d3f94;
  padding: 0 0 13px;
}
.emaillist {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #3d3f94;
  padding: 5px 0;
  background: #fffdfd;
}

.emaillist a {
  color: #404296;
  font-size: 18px;
  display: block;
  margin: 0 13px;
}
a.navbar-logo {
  background: #fff;
  display: block;
  padding: 5px 20px;
  border-radius: 0;
  width: 225px;
  text-align: center;
  margin: 0px 0 0px 0;
}
.navbar-logo img {
  max-width: 50px;
}
.gcolor1 .img-data {
  background: #138dfd;
  border-radius: 100%;
}

.gcolor2 .img-data {
  background: #ff7979;
}

.gcolor3 .img-data {
  background: #ff76e1;
}

.gcolor4 .img-data {
  background: #ffa562;
}

.gcolor5 .img-data {
  background: #fc8c8c;
}

.gcolor1 {
  border-color: #d9e8fe !important;
  color: #2c7be5;
}

.gcolor2 {
  border-color: #ffdfdf !important;
  color: #ff7979;
}

.gcolor3 {
  border-color: #f6d3e9 !important;
  color: #ff76e1;
}

.gcolor4 {
  border-color: #ffa562;
  color: #ffa562;
}

.gcolor5 {
  border-color: #ffd5d5 !important;
  color: #fc8c8c;
}
.Title-box {
  padding: 0;
  height: 100%;
  position: relative;
}

.BigTitle {
  font-size: 28px;
  font-weight: bold;
}

.CommanForm {
  justify-content: flex-end;
}

.CommanForm h4 {
  font-size: 15px;
  padding: 12px 20px 0;
}

.blue {
  color: #2c7be5;
}

.btn-comman {
  border: 2px solid #2c7be5;
  color: #2c7be5;
  border-radius: 41px;
  padding: 6px 20px;
}
.btn-comman:hover {
  background: #2c7be5;
  color: #fff;
}
.navbar-dashboard .container-fluid {
  padding: 0 1px;
}

.logo-dashboard img {
  max-width: 50px;
}
.content-box {
  padding: 98px 40px 50px 255px;
  width: 100%;
}

.side-nav ul {
  margin: 0;
  padding: 0;
}

.side-nav ul li {
  display: block;
}
div#adm24 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.cartbtn {
  color: #fff;
  border: 1px solid #fff;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 55px;
  display: block;
  font-weight: 400;
  width: 108px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  position: relative;
}
.PaymentStatus {
  position: absolute;
  right: 40px;
  top: 36px;
}

.btn-makepay {
  border: 2px solid #ff6584;
  color: #fff;
  /* font-weight: 700; */
  border-radius: 30px;
  /* width: 50%; */
  font-size: 12px;
  text-transform: uppercase;
  padding: 4px 18px;
  background: #ff6584;
  font-weight: 600;
}
.btn-makepay:hover {
  background: #fff;
  color: #ff6584;
}
.payment-done {
  color: #4dcfe0;
  vertical-align: middle;
  font-weight: 400;
  display: inline-flex;
}

.payment-done svg {
  width: 25px;
  margin-left: 14px;
  margin-top: -3px;
}

.Widthdata {
  margin: 30px 0;
}
.cartbtn span {
  position: absolute;
  text-align: center;
  left: -8px;
  top: -5px;
  background: #fff;
  color: #2c7be5;
  font-size: 18px;
  height: 25px;
  display: block;
  line-height: 22px;
  width: 25px;
  border-radius: 100%;
}
.cartbtn:hover {
  background: rgb(239 242 255 / 26%);
  color: #fff;
}

.cartdashboard {
  margin: 2px 20px 0 0;
}
.bar-box {
  padding: 12px 0;
}
.user-account {
  height: 40px;
  width: 40px;
  display: block;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  font-size: 18px;
  margin: 4px 20px 0 0;
  cursor: pointer;
  padding: 5px 14px;
  position: relative;
}
.user-account:hover .hoverlist {
  display: block;
}
.hoverlist {
  position: absolute;
  color: black;
  width: 100px;
  background: #fff;
  left: 0;
  display: none;
  font-size: 15px;
  text-align: left;
  padding: 5px 9px;
  border: 1px solid #ececec;
  top: 98%;
}

.hoverlist a {
  color: #202020;
  display: block;
}
.side-nav ul li a {
  display: block;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  text-decoration: none !important;
  font-size: 16px;
  border-bottom: 2px solid #2b8ddb;
  padding: 10px 25px 20px 25px;
}

.homeImg img {
  width: 100%;
  height: 100%;
}

.side-nav ul li button {
  display: block;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  text-decoration: none !important;
  font-size: 16px;
  border-bottom: 2px solid #2b8ddb;
  padding: 10px 25px 20px 25px;
}
.footer-process {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: linear-gradient(0deg, #2c7be5, #2c7be5);
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 22px 55px;
  align-content: center;
}
.Applicationdata.po0 {
  padding: 0 15px;
}

.Allprocess {
  width: 100%;
  padding-top: 2px;
  border-radius: 10px;
  background: #fff;
}

.Allprocess ul li {
  /* opacity: 0.7; */
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 16px;
  color: #8b9eb5;
  margin: 0 27px 0 20px;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  width: 100%;
  padding: 0 69px 27px 5px;
}
table.table {
  background: #fff;
  /* box-shadow: 0 0px 3px 9px rgb(0 0 0 / 4%), 0 1px 3px rgb(0 0 0 / 10%); */
}

.table td {
  font-size: 13px;
  color: #1c1c1c;
  font-weight: 500;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 0;
  white-space: nowrap;
  background: #004fff;
  color: #fff;
  border-right: 1px dashed #d3d8e8;
}
.Rollno {
  font-weight: 600;
  font-size: 14px;
  color: #12b2fe;
}

.col-Datesw {
  display: flex;
  border-bottom: 1px dashed #f0f0f0;
  padding: 2px 0 2px;
  font-size: 12px;
}

.col-Datesw h5 {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 0 4px;
  color: #a6a3a3;
  margin: 0;
  text-transform: uppercase;
}

.col-Datesw h6 {
  font-weight: 600;
  margin: 4px 0 0 6px;
  font-size: 13px;
}

.cartTopf .QVasName {
  font-size: 14px;
}
.btn-makepayD {
  border: 2px solid #1b8fbb;
  color: #fff;
  /* font-weight: 700; */
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 12px;
  /* width: 50%; */
  padding: 4px 14px;
  background: #1b8fbb;
  font-weight: 600;
  margin: 0 0 0 12px;
}

a.payNoed {
  display: block;
  font-size: 11px;
  font-weight: 700;
  margin: 0px 0 0 9px;
  border: 1px solid #ebf7f7;
  border-radius: 11px;
  padding: 1px 12px;
}
.cartTopf .cartImg {
  text-align: center;
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.progress.vertical {
  margin-top: -3px;
}
h6.gnr {
  color: #e67979;
}
.PaymentStatusd {
  text-align: center;
  padding: 0px 0 0;
  display: flex;
}
.logo3 {
  padding: 0px 0 0;
  max-width: 112px;
  color: #3d3f94;
}
.userSignup h5 {
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 2px;
  color: #ff4700;
}
.userSignup h4 {
  font-weight: bold;
  color: #3d3f94;
  font-size: 17px;
  margin: 0 0 4px 0;
  text-transform: capitalize;
}
.userSignup h6 {
  font-size: 15px;
  color: #2b2727;
  font-weight: 500;
  margin: 0 0 3px;
}
.cartTopf .cartImg img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}
.progress.vertical .progress-bar {
  background: #fff;
  border-radius: 10px;
}

.Allprocess ul {
  margin: 0;
  padding: 40px 35px 15px;
  position: relative;
}

.btn-finalsubmit {
  color: #2c7be5;
  background: #fff;
  border-radius: 80px;
  max-width: 172px;
  width: 100%;
  display: block;
  margin-left: auto;
}

.finalsubmit {
  width: 200px;
  text-align: right;
}

.Allprocess ul li.active {
  width: 100%;
  /* opacity: 1; */
  color: #000;
}

.Allprocess ul li:first-child {
  opacity: 1;
  margin-right: 0;
}

.Formbox {
  /* background: #fff; */
  margin: 0px 0 30px;
  padding: 15px 40px;
  border-radius: 10px;
  height: 100%;
  position: relative;
}

.boxlabel p {
  color: #474747;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0 8px;
}

.boxlabel {
  margin: 0 0 15px;
}
.Radioc {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.Radioc input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checC {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.Radioc:hover input ~ .checC {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.Radioc input:checked ~ .checC {
  background-color: #fff;
}
.btn-save {
  background: #ff6584;
  color: #fff;
  border-radius: 50px;
  padding: 8px 22px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checC:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.Radioc input:checked ~ .checC:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.Radioc .checC:after {
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #2c7be5;
}
.boxlabel .form-control {
  padding: 8px 10px;
  /* cursor: pointer; */
  border-radius: 2px;
  border-color: #cbcbcb;
  height: auto !important;
}

.formTitle {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.btn-group {
  display: block;
  text-align: right;
}

.btn-Next {
  background: #ff6584;
  color: #fff;
  border-radius: 50px;
  padding: 8px 22px;
}

.btn-Next span {
  margin-left: 35px;
}

.btn-cancle {
  border: 1px solid #8b9eb5;
  color: #8b9eb5;
  background: #fff;
  border-radius: 53px;
  padding: 8px 35px;
  margin-right: 13px;
}
.side-nav ul li a:hover,
.side-nav ul li a.active {
  color: #ffffff;
}
.side-nav ul li a:hover svg path,
.side-nav ul li svg path {
  fill: #fafafa;
}
.side-nav ul li a span svg {
  width: 30px;
  fill: #000;
}
.box-gift {
  border: 2px solid #a18ce2;
  border-radius: 10px;
  margin: 26px 0;
  padding: 16px 15px;
  background: linear-gradient(
      0deg,
      rgba(170, 128, 249, 0.05),
      rgba(170, 128, 249, 0.05)
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
}

.box-giftstatus {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.box-statusgift {
  padding: 20px 0 0 18px;
}

.box-statusgift h2 {
  color: #7488a1;
  font-weight: 600;
  font-size: 28px;
}

.box-statusgift h3 {
  color: #a18ce2;
  font-weight: 600;
  font-size: 33px;
}

.box-giftImg {
  max-width: 130px;
}

.cuponbox {
  text-align: right;
  padding: 45px 0 0;
}

.coupencode {
  background: #a18ce2;
  color: #fff;
  border-radius: 50px;
  padding: 10px 22px;
}

.coupenapply {
  border: 2px solid #a18ce2;
  color: #a18ce2;
  border-radius: 50px;
  padding: 10px 22px;
}

.cuponbox li {
  display: inline-block;
  margin: 0 10px 0 0;
}
.sideBar {
  background: #2c7be5;
  overflow-y: auto;
  width: 226px;
  position: fixed;
  padding: 50px 11px;
  height: 100%;
  left: 0px;
  top: 62px;
  border-radius: 3px;
  box-shadow: 0px 0px 8px 0 rgb(136 136 136 / 17%);
}
/*
.cover-bar {
 position: absolute;
    background: #2C7BE5;
    height: 98%;
    top: 9px;
    right: 11px;
    width: .4em;
    -webkit-transition: all .5s;
    opacity: 1;
}
*/
/* MAGIC HAPPENS HERE */
/*
.sideBar:hover .cover-bar {
   opacity: 0;
  -webkit-transition: all .5s;
}
*/
/*.side-nav  {overflow-y: scroll; height: 100%;}*/
/*
.side-nav:hover{overflow: auto; }
.side-nav::-webkit-scrollbar {
  width: 1px;overflow:visible;
}
*/

.sideBar::-webkit-scrollbar {
  /* display: none; */
}

/* Track */
.side-nav::-webkit-scrollbar-track {
  background: #4c95f7;
}

/* Handle */
.side-nav::-webkit-scrollbar-thumb {
  background: #095bc9;
  overflow: visible;
  border-radius: 4px;
}

/* Handle on hover */
.side-nav::-webkit-scrollbar-thumb:hover {
  background: #095bc9;
}
.side-nav ul li a span {
  display: block;
  padding: 0 0 0px 0;
  margin: 0 0 -5px;
}

.side-nav ul li a span img {
  max-width: 25px;
}
.dashboard-pagination {
  display: table;
  width: 100%;
  padding: 0 0 22px 0;
}

.dashboard-pagination h2 {
  float: left;
  color: #949090;
  font-weight: 500;
  font-size: 22px;
}

.dashborad-b {
  float: right;
  max-width: 1000px;
}

.dashborad-b ol.breadcrumb {
  background: transparent;
  padding: 0;
}

.dashborad-b ol.breadcrumb li.breadcrumb-item {
  font-weight: 600;
}

.grid-data {
  border: 1px solid;
  display: flex;
  background: #fff;
  padding: 25px 15px 10px;
  border-radius: 10px;
}
/* 
.grid-box .col-md-2 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 10px;
} */

.img-data img {
  max-width: 44px;
}

.grid-content {
  padding: 0 0 0 15px;
}

.rollNOads {
  display: flex;
  flex-wrap: wrap;
  padding: 21px 19px 19px;
}

.Leftdiv {
  width: 162px;
  border: 1px solid #acabab;
  padding: 11px 10px;
}

.rightdiv {
  width: calc(100% - 195px);
  padding: 0 0 0 30px;
}

.Rollnumberobox {
  background: #fff;
  padding: 0;
  border: 1px solid #dbdbdb;
  max-width: 600px;
  margin: 0 auto;
}

.Boxroll {
  font-size: 12px;
  background: #585858;
}

.Boxroll h1 {
  font-size: 23px;
  text-align: center;
  color: #fff;
  padding: 7px 0;
}

.DateRr span {
  font-weight: 400;
  font-size: 15px;
  color: #7b7575;
}

.DateRr {
  margin: 0 0 7px;
  color: #070707;
}

.Exmcen {
  font-weight: 800;
}

.Examcenter {
  margin: 0 0 13px 0;
}
.grid-content h5 {
  font-size: 15px;
  font-weight: 400;
  /* color: #343434; */
  margin: 1px 0 10px;
}

.grid-content h6 {
  color: #070606;
  font-size: 12px;
  font-weight: 500;
}

.grid-content h6 span {
  font-size: 15px;
  color: #000;
  margin: 0 0 0 4px;
}

.img-data {
  padding: 7px 0 0 0;
  width: 44px;
  text-align: center;
  height: 44px;
  border-radius: 100%;
}

.grid-box {
  margin: 25px 0 30px 0;
  /* padding: 0 10px; */
}
.areaboard {
  display: none;
}
.graphs img {
  width: 100%;
  height: 286px;
}
span.NOtic {
  font-size: 13px;
  display: block;
  color: #b9b6b6;
  font-weight: 300;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #aaaaaa;
  font-size: 13px;
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #aaaaaa;
  font-size: 13px;
}
.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #aaaaaa;
  font-size: 13px;
}
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #aaaaaa;
  font-size: 13px;
}
.fathersdetails,
.motherdetails,
.chekfather,
.chekmother {
  display: none;
}
.fathersdetails.active,
.motherdetails.active,
.chekfather.active,
.chekmother.active {
  display: block;
}
.graph-box {
  margin: 25px 0 50px 0;
  padding: 0 10px;
}
.permanent {
  display: none;
}
.graph-box .col-md-7 {
  padding: 0 10px;
}

.graph-box .col-md-5 {
  padding: 0 10px;
}
.dashborad-b ol.breadcrumb li.breadcrumb-item a {
  color: #6c757d;
}
.common-form-box {
  width: 100%;
  padding: 100px 60px 100px;
}

.btn-goback {
  border: 1px solid #7488a1;
  color: #7488a1;
  border-radius: 41px;
  text-transform: capitalize;
  padding: 8px 20px;
}
.vertical {
  width: 5px;
  min-height: 30px;
  display: flex;
  align-items: flex-end;
  background: #1761c4;
  margin-right: 12px;
  float: left;
}

.vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}

.serchbtn {
  display: none;
}
.mobile-menu {
  display: none;
}

.Formbox2 {
  background: #fff;
  padding: 20px;
  margin: 20px 0 0;
}

.Commmon2 {
  max-width: 768px;
  margin: 50px auto;
}

.commonfeature {
  background: #fffbed;
  padding: 20px;
  text-align: center;
  border-radius: 6px;
  min-height: 214px;
}

.commonFeadata h4 {
  font-size: 18px;
  font-weight: 700;
}

.commonFeadata p {
  color: #61748b;
  font-weight: 500;
  font-size: 16px;
}
.shortList {
  background: #fff;
  padding: 46px 0;
  text-align: center;
}
.shortlist {
  color: #7488a1;
  margin: 30px 0 0;
}
#icon-containers {
  max-width: 250px;
  margin: 0 auto;
}
.commonFeadata {
  padding: 0 22px;
}

.commonfeaturei {
  margin: 0 0 20px;
}

.Common-Boxx {
  margin-top: 40px;
}

.commonimg {
  text-align: center;
}

.commonimg h4 {
  color: #2b4d66;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 14px;
}

.commonimg h5 {
  color: #7488a1;
  font-weight: 500;
}

.letstart {
  text-align: center;
  margin-top: 50px;
}

.btn-letsartt {
  background: #ff6584;
  color: #fff;
  border-color: #ff6584;
  padding: 12px 44px;
  font-weight: 700;
  border-radius: 35px;
}

span.btn.btn-goback {
  margin-right: 8px;
  background: #fff;
}

span.btn-goback svg {
  margin: 0px 5px -2px;
}

span.btn-goback i {
  font-size: 20px;
  margin-left: 3px;
}

.btn-cancle svg {
  margin-right: 25px;
}

.btn-nextprocess {
  background: #49bcbb;
  color: #fff;
  border-radius: 50px;
  padding: 10px 30px;
}
.Fileboxbale {
  background: rgba(255, 255, 255, 0.5);
  border: 2px dashed #eaeaea;
  margin: 0 0 23px 0;
  text-align: center;
  padding-bottom: 10px;
  /* padding: 44px 38px; */
  cursor: pointer;
  border-radius: 10px;
}

.Fileboxbale .custom-file-input {
  /* height: 100%; */
  /* position: absolute; */
  width: 100%;
}
.myForm {
  background: #fff;
  padding: 30px;
  /* box-shadow: 0 0 3px rgb(0 0 0 / 20%); */
}
.Fileboxbale img {
  width: 209px;
}

.Fileboxbale h4 {
  color: #2b4d66;
  font-size: 18px;
  margin: 25px 0 0;
}

.Fileboxbale p {
  color: #9e9898;
  font-weight: 500;
  margin: 4px 0 0;
}

@media (max-width: 600px) {
  /* .cartitembox .profile-item{
    border-bottom: 2px solid black;
  } */
  .mobile-menu {
    display: block;
    margin: 0 10px 0 0px;
    background: transparent;
    border: 0;
    padding: 0;
    font-size: 20px;
    display: inline-block;
  }
  a.navbar-logo{
    width: auto;
  }
  .logo-dashboard img {
    max-width: 40px;
  }
  .college-name {
    padding: 18px 0 0 40px;
    display: none;
  }
  .navbar-dashboard .container-fluid {
    padding: 0 10px;
  }
  .search-dashboard {
    display: none;
  }
  .notification-bar {
    width: 100%;
    padding: 0 0 0 0px;
  }
  .active-bar {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0 0 0;
  }
  .user-account img {
    margin-left: 15px;
    border-radius: 30px;
    width: 30px;
    height: 30px;
  }
  .msq-box,
  .bell-notification {
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 100%;
    color: #999999;
    background: #f7f8ff;
    font-size: 12px;
    margin: 0px 10px;
    padding: 1px 8px;
    position: relative;
  }
  .msq-box span {
    position: absolute;
    right: -5px;
    top: 0px;
    background: #fc8c8c;
    color: #fff;
    font-size: 10px;
    height: 15px;
    display: block;
    line-height: 12px;
    width: 15px;
    border-radius: 100%;
  }
  .navbar-dashboard {
    background: #fff;
    height: 66px;
    padding: 6px 0;
  }

  .bell-notification span {
    position: absolute;
    right: -5px;
    top: 0px;
    background: #00aafe;
    color: #fff;
    font-size: 10px;
    height: 15px;
    display: block;
    line-height: 12px;
    width: 15px;
    border-radius: 100%;
  }
  /* .grid-box .col-md-2 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 10px;
  } */
  .sideBar {
    /* background: #fff; */
    width: 178px;
    position: fixed;
    display: none;
    padding: 17px 11px;
    height: 100%;
    left: 0;
    z-index: 2;
    top: 56px;
    border-radius: 0;
    box-shadow: 2px 0px 8px 0 rgb(136 136 136 / 17%);
  }
  .serchbtn {
    padding: 0;
    margin: 0 6px 0 0px;
    color: #9c9999;
    display: block;
    line-height: 13px;
  }
  .content-box {
    padding: 98px 10px 0 10px;
    width: 100%;
  }
  .grid-data {
    display: flex;
    background: #fff;
    padding: 16px 10px;
    margin-bottom: 12px;
    height: 130px;
  }
  .img-data img {
    max-width: 34px;
  }
  .grid-content {
    padding: 0 0 0 8px;
  }
  .grid-content h5 {
    font-size: 12px;
    font-weight: 600;
    color: #343434;
    margin: 4px 0 10px;
  }
  .grid-content h6 {
    color: #abaaaa;
    font-size: 12px;
    font-weight: 300;
  }
  .navbar-dashboard .row {
    width: 100%;
    margin: 0;
  }
}
